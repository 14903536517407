import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refunds-and-chargebacks',
  templateUrl: './refunds-and-chargebacks.component.html',
  styleUrls: ['./refunds-and-chargebacks.component.scss']
})
export class RefundsAndChargebacksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
