import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  constructor(
    private _location: Location,
  ){}
  goBack(){
    this._location.back()
  }

  projects: any = [
    {
      id: '1',
      name: 'ALS',
      mainCategory: 'Web',
      category: 'Saloon',
      image: [
        'assets/projects-images/ALS/Screenshots/lOUNGE.PNG',
        'assets/projects-images/ALS/Screenshots/home_page_Artisan_Retail.PNG',
        'assets/projects-images/ALS/Screenshots/Our_Cafe.PNG',
        'assets/projects-images/ALS/Screenshots/Connect.PNG',
        'assets/projects-images/ALS/Screenshots/Cafe_Menu.PNG',
        'assets/projects-images/ALS/Screenshots/Artisan_Image_Gallary.PNG',
      ],
      status: 'Completed',
      details: `<p>Arc Le Salon is an Artisan Retail, Cafe, Tobacco Retailer &amp; Bar. An attractive and exciting new venue where luxury and discretion are guaranteed for every guest. For this business entity we have created a web project where Different products have been provided in a showcase with different categories and subcategories. On the Landing page We have created an images gallery that will be opening in a separate popup. Alongside its description we have provided Content images so that give a luxurious touch. On the other side, to surf the different menu, to navigate the whole website we have provided a navigation slider on the left side, from here users can access to the different menus and the content of the web site. On a separate menu, we have created its categories and each category contains its sub categories with its description. And users can see the product details easily. For instance users can go on the cafe menu from the slider or from the landing page where, it can see , different available categories for food and beverages and as per the taste can choose one and that leads to different sub categories of the chosen product. We have also provided social media connect with some exciting looks so that visitors and users can directly connect to the social media business platforms like Facebook, Twitter and Instagram. At the bottom of the home page, we have also provided a special business contact form so that a visitor can connect to the owner of the respective team directly for any enquiry or business purpose. This Contact form has been laced with Google Captcha security so that businesses don&rsquo;t get any fake leads</p>
      `,
    },{
      id: '2',
      name: 'Concept Gurus',
      mainCategory: 'Web',
      category: 'Education',
      image: [
        'assets/projects-images/Concept_Gurus/Screenshots/Public/Homepage.PNG',
        'assets/projects-images/Concept_Gurus/Screenshots/Public/Login-profile_details_Student.PNG',
        'assets/projects-images/Concept_Gurus/Screenshots/Public/Global_Teachers.PNG',
        'assets/projects-images/Concept_Gurus/Screenshots/Public/Find_subjects.PNG',
      ],
      status: 'Completed',
      details: `<p>It&#39;s an online platform on which Teacher and student both interact with each other for online learning and teaching. Teachers can create a profile with the relevant subject that they carry expertise in the same subject. On the basis of the desired subject, a student can see the teacher and book an appointment if he finds the suitable fee structure too.. Once the payment is made the teacher will get the approval authority for the appointment and after that they could connect to each other with the video conferencing module that we have created. On this platform students can search board wise, class wise and subject wise specialists for ease. Whatever action is made on this platform, from login to appointment and appointment to Online lecture, will be notified through an email notification. We have created a public portal and an admin portal. Public portal is a footfall for general public and admin panel is a master control of this web application where admin can authorise the accounts of teacher and students along with creating subject and checking the new queries</p>`,
    }, {
      id: '3',
      name: 'Due Cash Screen Shots',
      mainCategory: 'Web',
      category: 'Game',
      image: [
        'assets/projects-images/Duel_Cash_Screen_Shots/web/2.PNG',
        'assets/projects-images/Duel_Cash_Screen_Shots/web/3.PNG',
        'assets/projects-images/Duel_Cash_Screen_Shots/web/4.PNG',
        'assets/projects-images/Duel_Cash_Screen_Shots/web/7.PNG',
        'assets/projects-images/Duel_Cash_Screen_Shots/web/ab.PNG',
        'assets/projects-images/Duel_Cash_Screen_Shots/web/ac.PNG',
        'assets/projects-images/Duel_Cash_Screen_Shots/web/profile.PNG',
      ],
      status: 'Completed',
      details: `<p>Duel Cash is a Gaming Web Application which gives immense wonderful gaming experience to the user. This web application has in app purchases which allows the user to money and gets rewards on the winnings. We have laced lot a more features. After Login, the user will get different options which helps to navigate through different features and functions of this web application. It will allow user to deposit the funds, withdraw funds, history, memberships and everything can be accessed</p>`,
    },{
      id: '4',
      name: 'Educational App',
      mainCategory: 'Web',
      category: 'Education',
      image: [
        'assets/projects-images/Educational_App/Screenshots/Student-Profile-details-page.PNG',
        'assets/projects-images/Educational_App/Screenshots/Impersonate-Institute-to-Admin.PNG',
        'assets/projects-images/Educational_App/Screenshots/DashBoard-Student-4.PNG',
        'assets/projects-images/Educational_App/Screenshots/Add-subject-Teacher.PNG',
        'assets/projects-images/Educational_App/Screenshots/Add_Sub-Topic_Syllabus_Teacher.PNG',
      ],
      status: 'Completed',
      details: `<p>This web application has been created considering several hierarchy which includes Administrator Institute, Teacher, Parents and Students.  In this system Admin (Master User) creates an Institute.  This Institute created receives Login Id and password through Email. The Registered institute can now log in and create Classes, add subjects, add syllabus, Teachers, parents, Students. Here a very dynamic functionality has been provided “Impersonate” with the help of this, master user can access to all of the institutes and perform their tasks without login from their account. Institute can create subscription type which will be adopted by the students on paid basis, can assign teachers to the different classes. In syllabus, institute can add different topics and sub-topics and add questions into them in all the subjects created and also can change the order of the topic in visibility.  In different questions, we can also add images and videos and their explanation.  A backup can also be created for the syllabus and this backup can be retrieved in the future. Result of the individual student can be seen. The same way Teachers, parents and students can be created and can see the details i.e Teachers can see students and parent details and parent can see the details of the students viz results and syllabus covered. A student can also review its performance and progress report from the dashboard. Apart from all these above shortly mentioned functionalities, we have also laced this system with video uploads, Live Video streaming and conference calls {one to one, one to many} so that a better teaching experience can be provided.</p>`,
    }, {
      id: '5',
      name: 'FS Cloud',
      mainCategory: 'Web',
      category: 'Accounts',
      image: ['assets/img/fs.png',
        'assets/projects-images/FS/screenshots/dashboard.PNG',
        'assets/projects-images/FS/screenshots/Journal-Entry.PNG',
        'assets/projects-images/FS/screenshots/Mapping-Data.PNG',
        'assets/projects-images/FS/screenshots/navigation-slider.PNG',
        'assets/projects-images/FS/screenshots/Title-hirarechy.PNG',
        'assets/projects-images/FS/screenshots/view-trial-balance.PNG'],
      status: 'Completed',
      details: `<p>In this era of cut throat competition in business, mere taking a right decision only not going to work. Business entities are in need to take right as well as quick decision to win the game or to change the game. The decision making is something, that to be chosen among the different available options and on the basis of available information. In business, right information at a right time is very much crucial as this only decides the future of entity. Numbers and figures are the backbone for decision making for a business entity. These figures are opted from the core documents of an organization i.e. Financial statements, Financial statements provides the exact financial position of the entity for a point of time as well as for a particular duration, that helps business owner, users and investors to analyse and take decisions. In this project a web application has been developed which is related to the financial statement. Through this application any company, organization, firm or small enterprise can prepare a financial statement of any year or for particular duration of a year or for a day even. In this application a proper panel for the user, admin hierarchy has been prepared for multi-level controlling, and from trial balance to the balance sheet could be extracted with this mechanism at any point of time. Various kinds of features have been laced like daily sales, total revenue, stock details could be looked in glance. This web application will assist its user in all the aspects of decision making in finance, with the help of data reporting, extracting, consolidation, comparison, interpretation and data analysis of the financial statements. This web application will be a great tool for any organisation to take good heights in the business and mostly in the competitive market.</p>`
      ,
    },{
      id: '6',
      name: 'Google Data Finder',
      mainCategory: 'Web',
      category: 'Data Finder',
      image: [
        'assets/projects-images/Google_Data_Finder/Screenshots/Dashboard.PNG',
        'assets/projects-images/Google_Data_Finder/Screenshots/Add-User.PNG',
        'assets/projects-images/Google_Data_Finder/Screenshots/Execution-Add-file.PNG',
        'assets/projects-images/Google_Data_Finder/Screenshots/Filter.PNG',
        'assets/projects-images/Google_Data_Finder/Screenshots/Login-Screen.PNG',
        'assets/projects-images/Google_Data_Finder/Screenshots/Results.PNG',
      ],
      status: 'Completed',
      details: `<div>
      <p>Google Data Finder is a cutting-edge web application designed to empower users with the remarkable capability of extracting up to 12,000 leads per hour from Google Search. Built with a robust and user-friendly interface, this application offers unparalleled convenience and efficiency in the process of lead collection. Its advanced features and functionalities provide users with a comprehensive toolset to effectively manage lead extraction, streamline workflows, and optimize data collection processes.</p>
      <h4>Key Features:</h4>
      <p><strong>High-Speed Lead Extraction: </strong>Google Data Finder&apos;s exceptional speed allows users to extract an impressive volume of 12,000 leads per hour, making it an indispensable asset for businesses and professionals seeking rapid and efficient data acquisition.</p>
      <p><strong>User-Friendly Interface: </strong>With a sleek and intuitive interface, the application ensures a seamless user experience, enabling users to navigate through the various functionalities effortlessly, even for those with minimal technical expertise.</p>
      <p><strong>Thread Execution Management:</strong> The application&apos;s sophisticated thread execution management system optimizes the speed and efficiency of the extraction process, ensuring smooth execution even during high-volume data retrieval tasks.</p>
      <p><strong>Customizable Filters and Sorting: </strong>Google Data Finder provides customizable filters for searches and sorting, allowing users to refine their search criteria and precisely target the specific leads they require. This feature enhances the accuracy and relevance of the extracted data, thereby saving valuable time and resources.</p>
      <p><strong>Keyword Exclusion and Inclusion:</strong> Users can effortlessly exclude or include specific keywords to fine-tune their search results, ensuring that the extracted leads are highly relevant to their specific requirements. This feature significantly enhances the precision and quality of the collected data.</p>
      <p><strong>Sub-User Management:</strong> The application offers a comprehensive sub-user management system, enabling organizations to efficiently manage and allocate access to different users within their teams. This feature facilitates seamless collaboration and ensures that the right individuals have access to the required data.</p>
      <p><strong>Data Security and Privacy:</strong> Google Data Finder prioritizes the security and privacy of user data, implementing robust security protocols to safeguard sensitive information and ensure compliance with data protection regulations.</p>
      <p><strong>Real-Time Monitoring and Reporting:</strong> Users can monitor the extraction process in real time and access detailed reports, providing valuable insights into the progress and results of the data extraction activities. This feature enables users to make informed decisions and optimize their lead generation strategies effectively.</p>
      <p>In summary, Google Data Finder is a state-of-the-art web application that redefines the lead extraction process, offering unparalleled speed, precision, and customization capabilities. With its advanced features and user-centric design, it serves as an indispensable tool for businesses, marketers, and professionals seeking to streamline their data collection efforts and drive meaningful results.</p>
  </div>`,
    },
    {
      id: '7',
      name: 'Kidla',
      mainCategory: 'Web',
      category: 'Education',
      image: [
        'assets/projects-images/Kidla/Admin/Dashboard.PNG',
        'assets/projects-images/Kidla/Admin/Questions.PNG',
        'assets/projects-images/Kidla/Admin/Sub-Topic.PNG',
        'assets/projects-images/Kidla/Admin/Topic.PNG',
        'assets/projects-images/Kidla/Admin/Add-Questions.PNG',
        'assets/projects-images/Kidla/Admin/Add-Sub-Topic.PNG',
        'assets/projects-images/Kidla/Admin/Classes.PNG',
      ],
      status: 'Completed',
      details: `<p>KIDLA is a platform for students where students of different classes can evaluate their skills and depth of knowledge for each subject. It is available upto class 12th. We have developed a public portal as well as an admin portal in this web application. In the public portal there will be a footfall of students where they can see the different classes and the relevant subject they want to test their skills with relevant topics. Here different functionalities have been provided like timeline, previews etc. Admin portal is the master control of this web application in which we can add different classes, different subjects , different topics and different sub topics and the most important , questions related to the same. The question could be added with different formats like, optional, single choice, one liner, images format graphical representations and etc. All the things we add on the admin panel are being reflected on the public portal.</p>`,
    },{
      id: '8',
      name: 'LMS',
      mainCategory: 'Web',
      category: 'Management',
      image: ['assets/img/sports.png',
        'assets/projects-images/League_Management_System/screenshots/LMS1.png',
        'assets/projects-images/League_Management_System/screenshots/LMS2.png',
        'assets/projects-images/League_Management_System/screenshots/LMS3.png',
        'assets/projects-images/League_Management_System/screenshots/LMS4.png',
        'assets/projects-images/League_Management_System/screenshots/LMS5.png',
        'assets/projects-images/League_Management_System/screenshots/LMS6.png',
      ],
      status: 'Completed',
      details: `<p>Client in this project is in a need of Sports league management software tool that will support their business to stay organised and to manage their multiple leagues in the same business basket. In this tool, being an administrator, wants a mechanism to create and manage league along with tournaments and user management. Administrator should also be able to schedule the matches, which would be played by the players as per their availability and time zone. It should support the functionality to view and resolve the dispute between the players for match.</p>`,
    }, {
      id: '9',
      name: 'Monitor 24/7',
      mainCategory: 'App',
      category: 'Monitoring',
      image: [
        'assets/projects-images/Monitor_24-7/Monitor-24x7-app-1.jpg',
        'assets/projects-images/Monitor_24-7/Monitor-24x7-app-2.jpg',
      ],
      status: 'Completed',
      details: `<p>Monitor 24/7 is an Android application we have developed to monitor the sign of life the loved ones. In this application user can monitor the loved one’s life signs by their movement.  The Idea is if the user of the app is having any movement in the phone gives the sign of life. If there is no movement in the device installed with app, for a particular period then well-wishers or the caregivers will be notified with the location, so that a faster assistance could be provided to the beloved one.  It works as Secure login, i.e. user login by filling OTP, can choose the role i.e Beloved-one or the CareGiver. If chooses to be Beloved-one then User can create CareGivers which will be sent as an Request in the email and once the careGiver accepts the request, will be CareGiver status will be shown as accepted and all the notifications related to Beloved one will be shared to the same. Along with this several Functionalities has been provided for better user Experience.</p>`,
    },{
      id: '10',
      name: 'Nagar Parisad Sikar',
      mainCategory: 'Web',
      category: 'Municipality',
      image: ['assets/img/snp.png',
        'assets/projects-images/SNP_WEB/Screenshot/Revenue_Portal/Dashboard.PNG',
        'assets/projects-images/SNP_WEB/Screenshot/Revenue_Portal/request-Section.PNG',
        'assets/projects-images/SNP_WEB/Screenshot/Revenue_Portal/Complaint-Section.PNG',
        'assets/projects-images/SNP_WEB/Screenshot/Revenue_Portal/Categories.PNG',
        'assets/projects-images/SNP_WEB/Screenshot/Revenue_Portal/Revenue-Form-Filling.PNG',
        'assets/projects-images/SNP_WEB/Screenshot/File_Portal/Add_ALCO.PNG'],
      status: 'Completed',
      details: `<p>File portal/File management system is  a web application, This application is used to manage municipal corporation and municipal council files for birth, death, marriage certificates, public complaint, public requests, revenue and budget etc. This system is integrated with SMS notifications, voice call, online payment for challans etc. File portal/File management system is  a web application, This application is used to manage municipal corporation and municipal council files for birth, death, marriage certificates, public complaint, public requests, revenue and budget etc. This system is integrated with SMS notifications, voice call, online payment for challans etc. </p>` ,
    },{
      id: '11',
      name: 'Nforce app',
      mainCategory: 'App',
      category: 'App Monitoring',
      image: [
        'assets/projects-images/SS_Nforce_app/nforce-app-1.jpg',
        'assets/projects-images/SS_Nforce_app/nforce-app-2.jpg'
      ],
      status: 'Completed',
      details: `<p>nForce Mobile application is a firewall based application which restrict the usage of the certain application, their inbound and outbound access to an extent. if admin has restricted for the use of (let say) Instagram then the device holder would not be able to use this application in any manner. Once this application is installed in the mobile of a person, it restricts its usage also on the basis of the network i.e if admin has restricted the use of application based on the Wi-Fi network then the application could not be used on Wi-Fi but can be used on personal network i.e. cellular network. If the admin has restricted the use of application on cellular network then the application could be used only on Wi-Fi. If the application has been restricted on the both networks then it cannot be used on any of the network. Similarly, the inbound or the outbound restriction or the access works on the application for the data.</p>`,
    },
  ]

  services: any = [
    { id: '1', url: 'enterprise-application', name: 'Enterprise application', icon: 'flaticon-plan',
     details: `<p>An enterprise application is a large software system platform designed to operate in a corporate environment such as business or government. It is complex, scalable, component-based, and distributed. Enterprise applications are mission-critical, meaning they are crucial to the operation of a business and often require significant resources to maintain. These applications are typically integrated with other software systems used within the organization, and they often require the support of a dedicated team for installation, maintenance, and updates.</p>
    
    <p>Enterprise applications serve various functions, such as:</p>
    
    <p><strong>Customer Relationship Management (CRM):</strong>
     Managing customer data, interactions, and relationships to improve customer satisfaction and drive sales growth.</p>
    
     <p><strong>Enterprise Resource Planning (ERP):</strong>
    Integrating core business processes such as finance, human resources, and supply chain management into a single system to streamline operations and facilitate decision-making.</p>
    <p><strong>Supply Chain Management (SCM):</strong>
    Monitoring the flow of goods, services, and information involved in the manufacturing and distribution process.</p>
    
    <p><strong>Business Intelligence (BI):</strong>
     Using data analysis tools and applications to provide insights into an organization&#39;s operations and make better business decisions.</p>
    
     <p><strong>Human Resource Management (HRM):</strong>
     Handling employee data, payroll, benefits administration, and other HR-related tasks.</p>
    
     <p><strong>Content Management System (CMS):</strong>
     Managing digital content, including documents, images, videos, and other media, for efficient storage, retrieval, and distribution.</p>
    
    <p>Enterprise applications are usually tailored to meet the specific needs of an organization, and they often require significant customization and integration with existing systems. They can be developed in-house by the organization&#39;s IT team or can be purchased from third-party vendors who specialize in enterprise software solutions.</p>
    
    <p>Security, scalability, reliability, and performance are critical factors in enterprise application development due to the sensitive nature of the data and the large number of users accessing the system. As a result, thorough testing, robust architecture, and adherence to industry best practices are crucial when developing enterprise applications.<br />
    &nbsp;</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },

    { id: '2', url: 'web-and-mobile-apps', name: 'Web & Mobile Apps', icon: 'flaticon-think', details: `<p>Web and mobile app development are two distinct but interconnected disciplines within the broader field of software development. They involve creating software applications that can be accessed and used on either a web browser or mobile devices, respectively. Here&#39;s a brief overview of each:</p>

    <p><strong>Web App Development:</strong>
    
    Web app development involves creating applications that are accessed through a web browser over the internet. These applications can range from simple static websites to complex dynamic web applications that interact with users, databases, and other services. Technologies commonly used in web app development include HTML, CSS, and JavaScript for front-end development, and various server-side languages such as PHP, Python, Ruby, or Node.js for back-end development. Popular web development frameworks include React, Angular, and Vue.js for front-end, and Django, Ruby on Rails, and Express.js for back-end.</p>
    
    <p><strong>Mobile App Development:</strong>
    
    Mobile app development involves creating applications specifically designed to run on mobile devices such as smartphones and tablets. Mobile apps can be developed for specific operating systems, such as Android or iOS, or they can be developed using cross-platform tools that allow developers to write code once and deploy it on multiple platforms. For iOS development, Swift or Objective-C are commonly used, while for Android development, Java or Kotlin are popular choices. Cross-platform tools like React Native, Flutter, and Xamarin have gained popularity for developing apps that work on both Android and iOS.<br />
    Both web and mobile app development require an understanding of user experience (UX) and user interface (UI) design principles to create intuitive and visually appealing applications. Security, performance, and scalability are also critical considerations in both types of development to ensure that the applications are robust and can handle increasing user demands.</p>
    
    <p>Moreover, ensuring compatibility across different browsers and devices is crucial in web development, while optimizing the user experience for different screen sizes and resolutions is important in mobile app development.</p>
    
    <p>In today&#39;s digital landscape, it&#39;s increasingly common for businesses to have both web and mobile applications to cater to a wider audience and provide a seamless user experience across different platforms.</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },


    { id: '3', url: 'desktop-apps', name: 'Desktop apps', icon: 'flaticon-shout', details: `<p>A desktop application, also known as a desktop app, is a computer program that is designed to run on a desktop computer or laptop. Unlike web or mobile applications, desktop apps are installed directly onto the user&#39;s computer and typically do not require an internet connection to function. These applications can offer a wide range of functionalities and are often more powerful and feature-rich than their web or mobile counterparts.</p>

    <p>Some key characteristics of desktop applications include:</p>
    
    <p><strong>User Interface:</strong>
    Desktop applications have user interfaces specifically designed for interaction on larger screens, making use of features like drag-and-drop, right-click options, and multitasking windows.</p>
    
    <p><strong>Performance: </strong>
    They can leverage the full processing power of the user&#39;s computer, allowing for more resource-intensive tasks and faster performance compared to web or mobile applications.</p>
    
    <p><strong>Offline Accessibility:</strong>
     Users can access and use desktop apps without requiring an internet connection, making them suitable for tasks that need to be performed in locations with limited or no internet access.</p>
    
     <p><strong>Integration:</strong>
     Desktop apps can often integrate more seamlessly with the operating system, enabling access to system resources, such as file systems and peripheral devices like printers and scanners.</p>
    
     <p><strong>Data Security: </strong>
    Desktop applications can be designed with a higher level of security, particularly for applications that handle sensitive or confidential data, as they can be developed to work in isolated environments with restricted access.</p>
    
    <p>Popular programming languages and frameworks used for developing desktop applications include:</p>
    
    <p><strong>Java:</strong>
    Offers the advantage of platform independence, allowing developers to create applications that can run on different operating systems.</p>
    
    <p><strong>C# and .NET:</strong>
    Widely used for developing Windows desktop applications, offering a rich set of libraries and tools for creating feature-rich applications.</p>
    
    <p><strong>Electron:</strong>
    Enables the development of cross-platform desktop applications using web technologies such as HTML, CSS, and JavaScript.</p>
    
    <p>C++: Known for its performance and the ability to create high-performance, resource-intensive applications.</p>
    
    <p>Desktop applications can serve a variety of purposes, ranging from office productivity tools, such as word processors and spreadsheets, to complex design and engineering software, as well as multimedia and entertainment applications. They remain an essential part of the software ecosystem, particularly for tasks that require high computational power or specialized hardware integration.<br />
    &nbsp;</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },


    { id: '4', url: 'ux-and-ui-desgins', name: 'UX & UI Desgins', icon: 'flaticon-analytics-1', details: `<p>User Experience (UX) design and User Interface (UI) design are two crucial components of the software development process. They are closely related but focus on different aspects of the overall design and user interaction. Here&#39;s an overview of each:</p>

    <p><strong>User Experience (UX) Design:</strong>
    UX design is the process of enhancing user satisfaction by improving the usability, accessibility, and overall interaction between the user and the product. It involves understanding the users&#39; needs, goals, and preferences and designing the product in a way that provides a seamless and enjoyable experience. Key aspects of UX design include user research, creating user personas, wireframing, prototyping, and usability testing. The goal of UX design is to ensure that the product is intuitive, efficient, and easy to use, leading to a positive and meaningful user experience.<br />
    User Interface (UI) Design:</p>
    
    <p>UI design focuses on the look and feel of the product, encompassing the visual elements such as layout, color schemes, typography, and interactive elements that users interact with. It involves creating visually appealing designs that are both aesthetically pleasing and functional. UI designers work on creating an interface that is easy to navigate, visually engaging, and aligns with the overall brand identity. They collaborate closely with UX designers to ensure that the interface design complements and enhances the overall user experience.<br />
    Key considerations in UX and UI design include:</p>
    <p><strong>User-Centric Approach:</strong>
    Both UX and UI design should prioritize the needs and preferences of the end users, ensuring that the product is intuitive and easy to navigate.</p>
    
    <p><strong>Consistency and Visual Hierarchy:</strong>
    Creating a consistent design language and maintaining a clear visual hierarchy helps users understand and navigate the interface more effectively.</p>
    
    <p><strong>Accessibility:</strong>
    Ensuring that the product is accessible to users with disabilities, incorporating features such as screen readers and keyboard navigation, is crucial for inclusive design.</p>
    
    <p><strong>Responsive Design:</strong>
    Designing interfaces that adapt seamlessly to different screen sizes and devices to provide a consistent user experience across various platforms.</p>
    
    <p><strong>Feedback and Iteration:</strong>
    Collecting user feedback and continuously iterating on the design based on user input and testing helps in refining the product and enhancing the overall user experience.</p>
    
    <p>Both UX and UI design play a critical role in the success of a product by ensuring that it is not only visually appealing but also functional, intuitive, and capable of meeting the needs and expectations of the users.</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },
    { id: '5', url: 'automations', name: 'Automations', icon: 'flaticon-ux-design', details: `<p>Web automation refers to the process of automating tasks on the web, typically using software or scripts to perform repetitive and time-consuming actions that would otherwise require manual intervention. It involves utilizing tools and technologies to streamline and optimize various online processes, saving time and increasing efficiency. There are several approaches to web automation, including:</p>

    <p><strong>Web Scraping:</strong>
    Web scraping involves extracting data from websites. It is commonly used to gather information from various sources on the internet automatically. This data can be used for various purposes such as market research, competitor analysis, and content aggregation. Popular tools for web scraping include BeautifulSoup and Scrapy in Python.</p>
    
    <p><strong>Automated Testing:</strong>
    Automated testing is the process of using software to execute tests on web applications. It helps ensure that the application functions as expected and that any changes or updates do not introduce new bugs or issues. Selenium and Puppeteer are widely used for automated testing of web applications.</p>
    <p><strong>Form Filling and Data Entry:</strong>
    Automating form filling and data entry tasks can significantly reduce the time and effort required to input data into web forms. This can be particularly useful for tasks such as online registrations, submissions, or data migration.</p>
    <p><strong>Web Task Automation:</strong>
    Web task automation involves automating routine tasks performed on the web, such as sending automated emails, updating spreadsheets with web data, or automatically posting content to various social media platforms. Tools like Zapier and IFTTT provide a user-friendly interface for automating such tasks.</p>
    <p><strong>Web Interaction Automation: </strong>
    This involves automating user interactions with web pages, including clicking buttons, filling out forms, and navigating through websites. This can be used for various purposes, such as automating repetitive workflows or streamlining online transactions.</p>
    
    <p>Common technologies and programming languages used for web automation include Python, JavaScript, and tools like Selenium WebDriver, Puppeteer, and UIPath. These tools offer a range of features and functionalities that enable developers to create custom automation solutions for various web-based tasks.</p>
    
    <p>Web automation can significantly improve productivity, reduce errors, and streamline processes, making it a valuable practice for businesses and individuals looking to optimize their online workflows and operations. However, it is essential to adhere to ethical practices and legal considerations while automating web tasks to ensure compliance with the terms of service of the websites involved.<br />
    &nbsp;</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },
    { id: '6', url: 'digital-marketing', name: 'Digital Marketing', icon: 'flaticon-camera', details: `<p>Digital marketing refers to the use of digital channels, platforms, and technologies to promote products, services, or brands to a targeted audience. It encompasses various online marketing strategies aimed at engaging with potential customers, building brand awareness, and driving sales and conversions. Some of the key components of digital marketing include:</p>

    <p><strong>Search Engine Optimization (SEO):</strong> SEO focuses on improving a website&#39;s visibility in search engine results pages through organic techniques. This involves optimizing website content, meta tags, and backlinks to improve the website&#39;s ranking and drive organic traffic.</p>
    
    <p><strong>Search Engine Marketing (SEM):</strong> SEM involves paid advertising efforts to increase a website&#39;s visibility in search engine results pages. This is typically done through pay-per-click (PPC) advertising campaigns on platforms like Google Ads and Bing Ads.</p>
    
    <p><strong>Content Marketing:</strong> Content marketing involves creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience. Content can include blog posts, articles, videos, infographics, and more.</p>
    
    <p><strong>Social Media Marketing (SMM):</strong> SMM involves leveraging social media platforms like Facebook, Instagram, Twitter, and LinkedIn to connect with the target audience, build brand awareness, and drive engagement and website traffic.</p>
    
    <p><strong>Email Marketing:</strong> Email marketing involves sending targeted promotional messages or newsletters to a list of subscribers. It is used to build customer relationships, promote products or services, and drive sales.</p>
    
    <p><strong>Influencer Marketing:</strong> Influencer marketing involves partnering with influential individuals or personalities to promote products or services. These influencers have a dedicated following and can help brands reach a larger audience.</p>
    
    <p><strong>Affiliate Marketing:</strong> Affiliate marketing is a performance-based marketing strategy where businesses reward affiliates for each customer or visitor brought through the affiliate&#39;s marketing efforts.</p>
    
    <p><strong>Pay-Per-Click Advertising (PPC):</strong> PPC advertising involves placing ads on search engines and other digital platforms and paying a fee each time the ad is clicked. This method can drive immediate traffic and results.</p>
    
    <p><strong>Digital Analytics and Tracking:<strong> Utilizing tools like Google Analytics to track and analyse digital marketing efforts to understand customer behaviour, preferences, and campaign performance.</p>
    
    <p>Digital marketing allows businesses to reach a global audience, target specific demographics, measure marketing efforts in real-time, and adjust strategies for optimal results. It is a dynamic and ever-evolving field that requires staying updated with the latest trends, tools, and best practices to effectively reach and engage with the target audience.</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },

    { id: '7', url: 'business-analytics', name: 'Business Analytics', icon: 'flaticon-project', details: `<p>Business analytics refers to the practice of using data analysis and statistical methods to derive insights from business data and make informed decisions. It involves the use of various techniques, tools, and technologies to interpret complex data sets and uncover patterns, trends, and correlations that can help businesses optimize their operations, improve efficiency, and make strategic decisions. Some key components of business analytics include:</p>

    <p><strong>Descriptive Analytics:</strong> Descriptive analytics involves examining past data to understand what has happened in a business. It includes techniques such as data visualization, reporting, and dashboarding to provide a clear picture of historical performance.</p>
    
    <p><strong>Diagnostic Analytics:</strong> Diagnostic analytics focuses on determining why certain events occurred in the past. It involves conducting root cause analysis and exploring relationships within data to identify factors that contributed to specific outcomes.</p>
    
    <p><strong>Predictive Analytics:</strong> Predictive analytics uses historical data to forecast future trends and behaviors. It employs statistical models and machine learning algorithms to make predictions about future events and outcomes, enabling businesses to anticipate potential challenges and opportunities.</p>
    
    <p><strong>Prescriptive Analytics:</strong> Prescriptive analytics goes beyond predicting future outcomes by suggesting possible actions to optimize results. It helps businesses make data-driven decisions by providing recommendations and strategies for achieving specific business objectives.</p>
    
    <p><strong>Data Mining:</strong> Data mining involves extracting patterns and knowledge from large data sets using various techniques such as machine learning, statistical analysis, and database systems. It helps businesses discover valuable insights and trends that are not readily apparent.</p>
    
    <p><strong>Data Visualization:</strong> Data visualization is the graphical representation of data and information. It includes the use of charts, graphs, and dashboards to present complex data sets in a visually appealing and easy-to-understand format, enabling businesses to identify trends and patterns quickly.</p>
    
    <p>Business Intelligence (BI): Business intelligence refers to the tools, technologies, and strategies used to collect, analyze, and present business information. It helps organizations make data-driven decisions by providing valuable insights into various aspects of their operations.</p>
    
    <p>Business analytics is integral to the decision-making process in modern businesses, enabling them to gain a competitive edge, identify new opportunities, and optimize their strategies for growth and success. By leveraging data effectively, businesses can improve operational efficiency, enhance customer experiences, and drive innovation and growth.</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },


    { id: '8', url: 'data-science', name: 'Data Science', icon: 'flaticon-data', details: `<p>Data science is an interdisciplinary field that involves the extraction of knowledge and insights from structured and unstructured data. It combines various techniques from statistics, mathematics, computer science, and domain knowledge to analyze and interpret complex data sets. Data scientists use a combination of programming skills, analytical abilities, and business acumen to uncover patterns, trends, and correlations that can help businesses make data-driven decisions. Key components of data science include:</p>

    <p><strong>Data Collection and Cleaning:</strong> Data scientists collect data from various sources, such as databases, APIs, and the web, and then clean and preprocess the data to ensure its quality and reliability for analysis.</p>
    
    <p><strong>Exploratory Data Analysis (EDA):</strong> EDA involves analyzing and visualizing data sets to identify patterns, trends, and relationships. It helps data scientists understand the underlying structure of the data and formulate hypotheses for further analysis.</p>
    
    <p><strong>Statistical Analysis:</strong> Data scientists apply statistical methods and models to analyze data and make predictions or inferences. This includes techniques such as regression analysis, hypothesis testing, and probability distributions.</p>
    
    <p><strong>Machine Learning:</strong> Machine learning algorithms are used to build predictive models and make data-driven predictions and decisions. These algorithms learn from data patterns and make data-driven predictions without being explicitly programmed.</p>
    
    <p><strong>Data Visualization:</strong> Data visualization is used to present data and analysis results in a visual format, making complex information more accessible and understandable to a wide audience. It includes the use of charts, graphs, and dashboards to communicate insights effectively.</p>
    
    <p><strong>Big Data Analytics:</strong> Data scientists work with large and complex data sets, often referred to as big data, using specialized tools and techniques to analyze and extract meaningful insights.</p>
    
    <p><strong>Deep Learning and Neural Networks:</strong> Deep learning techniques involve building and training neural network models to process complex data and perform tasks such as image recognition, natural language processing, and speech recognition.</p>
    
    <p><strong>Predictive Analytics:</strong> Predictive analytics involves using historical data to make predictions about future events or trends. It helps businesses make informed decisions and develop strategies based on anticipated outcomes.</p>
    
    <p>Data science plays a critical role in enabling businesses to optimize their operations, improve decision-making, and gain a competitive edge in today&#39;s data-driven world. By leveraging data effectively, organizations can identify new opportunities, mitigate risks, and enhance their overall performance and efficiency.</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' },

    { id: '9', url: 'graphic-design', name: 'Graphic Design', icon: 'flaticon-camera', details: `<p>Graphic design is the art and practice of planning and projecting visual and textual content. It involves creating visual concepts using computer software or by hand to communicate ideas that inspire, inform, or captivate consumers. Graphic designers work on a variety of products and activities, such as advertisements, brochures, magazines, corporate reports, and website design. Some key elements of graphic design include:</p>

    <p><strong>Typography:</strong> Typography is the art and technique of arranging type to make written language legible, readable, and appealing when displayed. Graphic designers use various fonts, sizes, and arrangements to convey the intended message effectively.</p>
    
    <p><strong>Page Layout:</strong> Page layout refers to the arrangement of text, images, and other elements on a page. It involves the organization and presentation of visual elements to create a balanced and visually appealing composition.</p>
    
    <p><strong>Color Theory:</strong> Color theory is the study of how colors interact with each other and how they can evoke certain emotions or convey specific messages. Graphic designers use color combinations strategically to create visual impact and convey the desired mood or tone.</p>
    
    <p><strong>Visual Hierarchy:</strong> Visual hierarchy refers to the arrangement and presentation of elements in a way that implies importance. Graphic designers use techniques such as size, color, contrast, and placement to guide the viewer&#39;s attention and emphasize key messages or elements.</p>
    
    <p><strong>Image Editing:</strong> Image editing involves enhancing or manipulating digital images to improve their quality, appearance, or relevance to the intended message. Graphic designers use software such as Adobe Photoshop or GIMP to edit and enhance images for various design projects.</p>
    
    <p><strong>Logo Design:</strong> Logo design involves creating unique and memorable symbols that represent a brand or a company. A well-designed logo serves as the visual identity of a business, communicating its values and creating brand recognition.</p>
    
    <p><strong>Print Design:</strong> Print design involves creating visual materials intended for printing, such as posters, flyers, business cards, and packaging. Graphic designers ensure that the design elements are optimized for high-quality printing and production.</p>
    
    <p><strong>Web Design:</strong> Web design involves creating the visual layout and elements of websites. It includes designing the user interface, navigation, and visual aesthetics to provide an engaging and user-friendly experience for website visitors.</p>
    
    <p>Graphic design is essential in creating visually appealing and effective communication materials for various purposes, including advertising, branding, and marketing. A well-executed graphic design can help businesses convey their message clearly and leave a lasting impression on their target audience.</p>
    `, slide1: 'assets/img/blog-details.jpg', slide2: 'assets/img/blog-details.jpg', slide3: 'assets/img/blog-details.jpg' }
  ]

  teams: any = [
    { id: '1', name: 'Deepak Soni', designation: 'Founder', image: 'assets/img/deepak.jpeg' },
    { id: '2', name: 'Rajesh Soni', designation: 'Co-Founder', image: 'assets/img/rajesh.jpeg' },
    { id: '3', name: 'Vikram jadiya', designation: 'Project Manager ', image: 'assets/img/vikram.jpeg' },
    { id: '4', name: 'Vijay', designation: 'UK Business partner', image: 'assets/img/vijay.jpeg' },
    { id: '5', name: 'Saiyam Kohli', designation: 'US Business partner', image: 'assets/img/saiyam.jpeg' }
  ]
}