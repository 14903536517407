import { Component, OnInit } from '@angular/core';
import { ConstantService } from '../../shared/constants.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  
  constructor(
    public constant : ConstantService
  ) { }

  ngOnInit() {
  }

}
