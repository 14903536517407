import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { UtilityService } from '../../shared/utility.service';
import { ConstantService } from '../../shared/constants.service';

@Component({
  selector: 'app-digital-agency',
  templateUrl: './digital-agency.component.html',
  styleUrls: ['./digital-agency.component.scss']
})
export class DigitalAgencyComponent implements OnInit {
  contact: any = { "name": '', "email": '', "phone": '', "subject": '', "message": '' }
  loading: boolean;
  constructor(
    private _userService: UserService,
    private _utilityService: UtilityService,
    public constant: ConstantService,
  ) {}
  ngOnInit() {
  }

  sendContact() {
    if (this.contact.name !== '' && this.contact.email !== '' && this.contact.phone !== '' && this.contact.subject !== '' && this.contact.message !== '') {
      if (this._utilityService.validateEmail(this.contact.email)) {
        this.loading = true;
        this._userService.sendContact(this.contact).then((response: any) => {
          this.loading = true;
          if (response && response.status === 'OK') {
            this._utilityService.openMatSnackBar(response.message, response.status);
            this.contact = { "name": '', "email": '', "phone": '', "subject": '', "message": '' }
          } else {
            this._utilityService.openMatSnackBar(response.message, response.status);
          }
        }, error => {
          this._utilityService.openMatSnackBar(error.error.message, 'ERROR');
        });
      } else this._utilityService.openMatSnackBar('Please enter a valid e-mail address ', 'Try Again');
    } else this._utilityService.openMatSnackBar('Please fill all required field', 'Try Again');
  }


  testimonial = [
    {
      name: 'Paul Eadington, UK',
      description: 'This man has delivered yet another fantastic project I have now been used numerous IT specialists. For over five years and over ten companies and p4logics is without doubt the best I have used. We will remain friends and it is that good I am enhancing this to a landing page I can see us working together for a long time I will go nowhere else from now in. Fantastic work ',
    },
    {
      name: 'Bryan Lee Johnson, US',
      description: 'P4logics delivered the result on time. His suggestions were very helpful in completing the project. They are developer who worked with customers to find solutions. Thank you so much.',
    },
    {
      name: 'Abdu U, UK',
      description: 'I was extremely satisfied with the work done by P4logics. They are very professional and listens to you carefully before starting the project. He constantly updated me on the progress of the project without me asking and finished it in time. The project went a bit out of scope, and they were happy to challenge the issue. I will definitely be using them again. Definitely the best I have used. Well done P4logics!!',
    },
    {
      name: 'Nicolas Arganaras, Argentina',
      description: 'It is a great team of developers, they do not rest until the problems are solved, I recommend working with them.',
    },
  ]
}
