import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalAgencyComponent } from './components/pages/digital-agency/digital-agency.component';
import { DigitalAgencyTwoComponent } from './components/pages/digital-agency-two/digital-agency-two.component';
import { DigitalAgencyThreeComponent } from './components/pages/digital-agency-three/digital-agency-three.component';
import { ProjectsComponent } from './components/pages/projects/projects.component';
import { ProjectDetailsComponent } from './components/pages/project-details/project-details.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DigitalAgencyFourComponent } from './components/pages/digital-agency-four/digital-agency-four.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { RefundsAndChargebacksComponent } from './components/pages/refunds-and-chargebacks/refunds-and-chargebacks.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';

const routes: Routes = [
  { path: '', component: DigitalAgencyComponent },
  //{ path: 'home-light', component: DigitalAgencyTwoComponent },
  //{ path: 'home-dark', component: DigitalAgencyComponent },
  // { path: 'digital-agency-demo-3', component: DigitalAgencyThreeComponent },
  // { path: 'digital-agency-demo-4', component: DigitalAgencyFourComponent },
  { path: 'about', component: AboutComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'project-details/:id', component: ProjectDetailsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'services/:url', component: ServiceDetailsComponent },
  { path: 'team', component: TeamComponent },
  //{ path: 'testimonials', component: TestimonialsComponent },
  //{ path: 'blog', component: BlogComponent },
  //{ path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'company-privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'refunds-and-chargebacks', component: RefundsAndChargebacksComponent },
  { path: 'pricing', component: PricingComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
