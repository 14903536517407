import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { CookieSettingsPopupComponent } from './components/pages/cookie-settings-popup/cookie-settings-popup.component';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    location: any;
    routerSubscription: any;
    activeRoute: any;
    index: number = 0;
    dialogRef: any;

    constructor(
        private router: Router,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
    ) {
        _router.events.subscribe((val: any) => {
            if (val.url && this.activeRoute !== val.url) {
                this.activeRoute = val.url;
                this.ngOnInit();
            }
        });
    }
    ngOnInit() {
        this.recallJsFuntions();

        this.isCookie();

    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.uk-preloader').fadeIn();
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.uk-preloader').fadeOut();
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0)
            });
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }



    isCookie() {
        var user = this.getCookie("user");//"test@gmail.com"
        if (user !== "" && user !== null) {
            document.getElementById("cookie").style.display = 'none';
        }
    }


    /**
     * Set Cookie
     * 
     * @param name 
     * @param value 
     * @param days 
     */
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    /**
     * Set Cookie Settings
     */
    setCookieSettings() {
        this.dialogRef = this._matDialog.open(CookieSettingsPopupComponent, {
            panelClass: 'contact-form-dialig',
            width: '600px'
        });
        this.dialogRef.afterClosed().subscribe((response: any) => {
            if (!response) {
                return;
            }
        });
    }
    /**
     * Accept Cookie
     */
    accept() {
        this.setCookie("user", "test@gmail.com", 30); //set "user" cookie, expires in 30 days
        document.getElementById("cookie").style.display = 'none';
    }

    close() {
        document.getElementById("cookie").style.display = 'none';
    }
}

