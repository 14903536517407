import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { UtilityService } from '../../shared/utility.service';
import { ConstantService } from '../../shared/constants.service';
import { FileUploadService } from '../../shared/file-upload.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../shared/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy{
  contactForm: FormGroup;
  contact: any = { "name": '', "email": '', "phone": '', "subject": '', "message": '' }
  loading: boolean;
  image: any;
  isSubmitButtonDisable: boolean = false;
  progressBarForFile: number = 0;
  captcha: string;
  constructor(
    private _userService: UserService,
    private _utilityService: UtilityService,
    public constant: ConstantService,
    public _fileUploadService: FileUploadService,
    public _dataService: DataService,
    // private recaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.contactForm = this._dataService.createContactForm();
    
    console.log('onInit', this._dataService.currentSelectedProject, this._dataService.timePeriod);
  }

  ngOnInit() {
  }

  sendContact() {
    // if (this.contact.name !== '' && this.contact.email !== '' && this.contact.phone !== '' && this.contact.subject !== '' && this.contact.message !== '') {
    //   if (this._utilityService.validateEmail(this.contact.email)) {
    //     this.loading = true;
    //     this._userService.sendContact(this.contact).then((response: any) => {
    //       this.loading = true;
    //       if (response && response.status === 'OK') {
    //         this._utilityService.openMatSnackBar(response.message, response.status);
    //         this.contact = { "name": '', "email": '', "phone": '', "subject": '', "message": '' }
    //       } else {
    //         this._utilityService.openMatSnackBar(response.message, response.status);
    //       }
    //     }, error => {
    //       this._utilityService.openMatSnackBar(error.error.message, 'ERROR');
    //     });
    //   } else this._utilityService.openMatSnackBar('Please enter a valid e-mail address ', 'Try Again');
    // } else this._utilityService.openMatSnackBar('Please fill all required field', 'Try Again');
    if(this.captcha){
      const json = {
        name: this.contactForm.controls['name'].value,
        email: this.contactForm.controls['email'].value,
        mobileNumber: this.contactForm.controls['mobileNumber'].value,
        whatsappNumber: this.contactForm.controls['whatsappNumber'].value,
        skypeNumber: this.contactForm.controls['skypeNumber'].value,
        address: this.contactForm.controls['address'].value,
        file: this.contactForm.controls['file'].value,
        projectBudget: this.contactForm.controls['projectBudget'].value,
        message: this.contactForm.controls['message'].value,
        timePeriod: this._dataService.timePeriod,
        currentSelectedProject: this._dataService.currentSelectedProject,
      };
    
      console.log('contactform', json);}
    else {
      this._dataService.openSnackBar("Please complete the reCAPTCHA.", 'close');
    }
    }
    
    ngOnDestroy(){
      this.contactForm.reset();
      this._dataService.currentSelectedProject = null;
      this._dataService.timePeriod = 'free';
      console.log('onDestroy', this._dataService.currentSelectedProject, this._dataService.timePeriod);
    }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }


  /**
  * On select file
  * 
  * @param event 
  */
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.uploadFile(event.target.files[0]);
    }
  }

  /**
   * Remove file
   */
  removeLogoImage() {
    this._fileUploadService.delete(this.image.originalUrl).then((response: any) => {
      if (response && response.status === 'OK') {
        this.image = "";
      } else {
      }
    })
  }

  /**
     * Upload File
     * 
     * @param multiPartFile 
     */
  uploadFile(multiPartFile: any) {
    this.isSubmitButtonDisable = true;
    let formData = new FormData();
    formData.append('file', multiPartFile);
    formData.append('fileName', `${multiPartFile.name}_${new Date().getTime()}`);
    formData.append('type', `product_images`);
    this._fileUploadService.uploadImage(formData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          this.progressBarForFile = Math.round(event.loaded / (event && event.total ? event.total * 100 : 0));
          console.log(`Uploaded! ${this.progressBarForFile}%`);
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          this.image = event.body.data;
          setTimeout(() => {
            this.progressBarForFile = 0;
            this.isSubmitButtonDisable = false;
          }, 1500);
      }
    });
  }

  resolved(captchaRespose: string) {
    this.captcha = captchaRespose;
    // console.log(this.captcha);
  }

}
