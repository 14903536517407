import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cookie-settings-popup',
  templateUrl: './cookie-settings-popup.component.html',
  styleUrls: ['./cookie-settings-popup.component.css']
})
export class CookieSettingsPopupComponent implements OnInit {

  cookieSettings: any = [
    { title: "Essential Cookies", description: "These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.", checked: true, disable: true },
    { title: "Enable Marketing Cookies", description: "These cookies are used to track advertising effectiveness to provide a more relevant service and deliver better ads to suit your interests.", checked: false, disable: false },
    { title: "Enable Functional Cookies", description: "These cookies collect data to remember choices users make to improve and give a more personalised experience.", checked: false, disable: false },
    { title: "Enable Analytics Cookies", description: "These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.", checked: false, disable: false }
  ]

  constructor(
    public matDialogRef: MatDialogRef<CookieSettingsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    matDialogRef.disableClose = true;
  }

  ngOnInit() {
  }

}
