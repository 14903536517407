import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    timePeriod: string = 'free';
    currentSelectedProject: any = null;

    constructor(
        private _formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
    ) {

    }

    createContactForm() {
        return this._formBuilder.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            mobileNumber: [null, [Validators.required, Validators.pattern('^(/+/d{1,4}/s)?[0-9]{10}$')]],
            whatsappNumber: [null, [Validators.required, Validators.pattern('^(/+/d{1,4}/s)?[0-9]{10}$')]],
            skypeNumber: [null, [Validators.required, Validators.pattern('^(/+/d{1,4}/s)?[0-9]{10}$')]],
            address: [null, [Validators.required]],
            file: [null],
            projectBudget: [null, [Validators.required]],
            message: [null, [Validators.required]],
            timePeriod: [null],
            currentSelectedProject: [null],
        })
    }
    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top' // Set the vertical position to 'top'
        });
    }
}
