import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from '../../shared/data.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  panelOpenState = false;
  currentSelectedProject: any;

  projectList = [
    {
      key: "fintech_application",
      type: "Fintech Application",
      typePassiveMention: "a Fintech Application",
      base_min: 20,
      base_max: 75
    }, {
      key: "data_mining_tool",
      type: "Data Mining Tool",
      typePassiveMention: "a Data Mining Tool",
      base_min: 45,
      base_max: 426
    }, {
      key: "open_banking_app",
      type: "Open Banking App",
      typePassiveMention: "an Open Banking App",
      base_min: 15,
      base_max: 183
    }, {
      key: "marketplace",
      type: "Marketplace",
      typePassiveMention: "a Marketplace",
      base_min: 35,
      base_max: 85
    }
  ]
  constructor(
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _dataService: DataService,
  ) {}

  onOptionSelect(event: any): void {
    this.currentSelectedProject = event.value;
  }

  ngOnInit() {
  }
  submit() { }


  onMouseEnter(id: any) {
    let element: HTMLElement = document.getElementById(id);
    let classElementList: HTMLCollectionOf<Element> = document.getElementsByClassName('p_cards');
    element.classList.add('hovered')
    for (var i = 0; i < classElementList.length; i++) {
      let idElement = classElementList[i].id;
      if (element.id !== idElement) {
        classElementList[i].classList.add('notHovered');
      }
    }

  }
  onMouseLeave(id: any) {
    let element: HTMLElement = document.getElementById(id);
    let classElementList: HTMLCollectionOf<Element> = document.getElementsByClassName('p_cards');
    element.classList.remove('hovered');
    for (var i = 0; i < classElementList.length; i++) {
      classElementList[i].classList.remove('notHovered');
    }
  }

  hireNow(timePeriod: any){
    if(!this.currentSelectedProject){
      this._dataService.openSnackBar('Pick your type of project.', 'Close');
    }else{
      this._dataService.currentSelectedProject = this.currentSelectedProject;
      this._dataService.timePeriod = timePeriod;
      console.log('hi',this._dataService.currentSelectedProject, this._dataService.timePeriod)
      this._router.navigate(['/contact']);
    }
  }
}
