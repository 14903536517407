import { Component, OnInit } from '@angular/core';
import { ConstantService } from '../../shared/constants.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  project: any;
 
  constructor(
    public constant : ConstantService,
    public _activatedRoute: ActivatedRoute,
    public _router: Router,
    private _location: Location,
    ) { }

  ngOnInit() {
    if (this._activatedRoute.snapshot.params.id)
      this.project = this.constant.projects.find(item => item.id === this._activatedRoute.snapshot.params.id);
  }
}
