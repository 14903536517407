import { Component, OnInit } from '@angular/core';
import { ConstantService } from '../../shared/constants.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss']
})
export class ServiceDetailsComponent implements OnInit {
  service: any;
 
  constructor(
    public constant : ConstantService,
    public route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    if (this.route.snapshot.params.url)
      this.service = this.constant.services.find(item => item.url === this.route.snapshot.params.url);
  }

  getService(url){
    this.router.navigate(['/services/'+url]);
    this.service = this.constant.services.find(item => item.url === url);
  }

}
